<template>
  <v-form
    class="public-page-form justify-center d-flex flex"
    @submit.prevent="formSignup">
    <v-card :flat="hasTwoPaneLayout">
      <v-card-text>
        <v-row>
          <v-col class="text-center">
            <span
              v-if="awsMarketplaceToken"
              class="public-page-form__step">
              {{ $t('forms.step') }} 1 / 2
            </span>
            <h2
              v-if="hasTwoPaneLayout"
              class="public-page-form__title mb-6">
              {{ $t('signUp') }}
            </h2>
            <router-link
              v-else
              :to="{ name: 'login' }">
              <CyAppPublicLogo class="public-page-form__logo"/>
            </router-link>
          </v-col>
        </v-row>

        <v-row v-if="!_.isEmpty(errors)">
          <v-col class="mx-6 px-3 pt-0">
            <CyAlert
              theme="error"
              :content="errors"/>
          </v-col>
        </v-row>

        <v-row class="mx-6">
          <v-col
            class="pr-2 pb-0"
            cols="6">
            <v-text-field
              v-model="user.givenName"
              :label="$t('fieldGivenName')"
              :error-messages="givenNameErrors"
              required
              class="required-field"
              autocomplete="given-name"
              @focus="$v.user.givenName.$reset()"
              @blur="$v.user.givenName.$touch()"/>
          </v-col>
          <v-col
            class="pl-2 pb-0"
            cols="6">
            <v-text-field
              v-model="user.familyName"
              :label="$t('fieldFamilyName')"
              :error-messages="familyNameErrors"
              required
              class="required-field"
              append-icon="person"
              autocomplete="family-name"
              @focus="$v.user.familyName.$reset()"
              @blur="$v.user.familyName.$touch() && $v.user.username.$touch()"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.username"
              :label="$t('forms.fieldUsername')"
              :error-messages="usernameErrors"
              :hint="$t('fieldUsernameHint')"
              persistent-hint
              required
              class="required-field"
              append-icon="account_circle"
              autocomplete="username"
              @input="onUserNameInput"
              @focus="$v.user.username.$reset()"
              @blur="$v.user.username.$touch()"/>
          </v-col>
        </v-row>
        <v-row class="mx-6">
          <v-col cols="12">
            <v-text-field
              v-model="user.email"
              :label="$t('forms.fieldEmail')"
              :error-messages="emailErrors"
              required
              class="required-field"
              append-icon="email"
              :readonly="isEmailFieldReadonly"
              @focus="$v.user.email.$reset()"
              @blur="$v.user.email.$touch()"/>
          </v-col>
        </v-row>
        <v-row
          v-if="!isOAuthSignup"
          class="mx-6">
          <v-col>
            <v-text-field
              v-model="user.password"
              :label="$t('forms.fieldPassword')"
              :error-messages="passwordErrors"
              :append-icon="passwordVisibility ? 'visibility_off' : 'visibility'"
              :type="passwordVisibility ? 'text' : 'password'"
              required
              class="required-field"
              autocomplete="new-password"
              @click:append="togglePasswordVisibility"
              @focus="$v.user.password.$reset()"
              @blur="$v.user.password.$touch()"/>
          </v-col>
        </v-row>
        <CyPasswordStrength
          v-model="passwordStrength"
          class="password-strength"
          :password="user.password"/>
        <v-row
          v-if="!isOAuthSignup"
          class="mx-6">
          <v-col>
            <v-text-field
              v-model="user.confirmPassword"
              :label="$t('fieldConfirmPassword')"
              :error-messages="confirmPasswordErrors"
              :append-icon="confirmPasswordVisibility ? 'visibility_off' : 'visibility'"
              :type="confirmPasswordVisibility ? 'text' : 'password'"
              required
              class="required-field"
              autocomplete="new-password"
              @click:append="toggleConfirmPasswordVisibility"
              @focus="$v.user.confirmPassword.$reset()"
              @blur="$v.user.confirmPassword.$touch()"/>
          </v-col>
        </v-row>
        <v-row class="mx-6">
          <v-col>
            <v-autocomplete
              v-model="user.countryCode"
              :items="countries"
              :label="$t('forms.fieldCountry')"
              class="required-field"
              item-text="name"
              item-value="code"
              required
              hide-selected
              autocomplete="country"/>
          </v-col>
        </v-row>
        <v-row class="mx-6">
          <v-checkbox
            v-model="$v.user.terms.$model"
            class="required-field"
            required/>
          <v-col class="py-0 pt-1 pl-1">
            <i18n path="checkboxText">
              <router-link
                class="login"
                target="_blank"
                :to="{ name: 'terms' }">
                {{ $t('routes.terms') }}
              </router-link>
              <router-link
                class="login"
                target="_blank"
                :to="{ name: 'privacy' }">
                {{ $t('routes.privacy') }}
              </router-link>
            </i18n>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :class="[
              'text-center pa-0',
              hasOAuthProviders ? 'ma-6 mb-8' : 'ma-6 mb-10',
            ]">
            <CyButton
              v-if="!isOAuthSignup"
              :loading="loading"
              :disabled="!canSave"
              type="submit">
              {{ awsMarketplaceToken ? $t('forms.btnContinue') : $t('btnCreateAccount') }}
            </CyButton>
            <CyButton
              v-if="isOAuthSignup && hasOAuthProviders"
              :loading="loading"
              :disabled="!canSave"
              @click.prevent="completeOAuthSignup()">
              {{ createAccountText }}
            </CyButton>
          </v-col>
        </v-row>
        <template v-if="!isOAuthSignup && hasSSOProviders">
          <v-row class="d-flex align-center px-9">
            <v-divider class="darkgrey"/>
            <span class="signup-with">{{ $t('signupWith') }}</span>
            <v-divider class="darkgrey"/>
          </v-row>
          <v-row class="mx-6 mt-6 mb-8">
            <v-col class="d-flex align-center justify-center space-x-4">
              <CySSOButton
                v-for="provider of socialProviders"
                :key="provider"
                :size="28"
                :loading="oidcLoading[provider]"
                :sso-provider="provider"
                @start-sso-flow="startOAuthFlow"/>
              <CySSOButton
                v-for="{ provider, sso_url } of appConfig.authentication.saml2"
                :key="provider"
                :size="36"
                sso-provider="saml"
                :sso-url="sso_url"
                @start-sso-flow="navigateToSaml2Provider"/>
            </v-col>
          </v-row>
        </template>
        <div
          v-if="!awsMarketplaceToken"
          class="text-center">
          {{ $t('alreadyHaveAccount') }}
          <router-link
            class="cy-link"
            :to="{ ...$route, name: 'login' }"
            data-cy="link-login"
            @click.native="$gtm.trackEvent($static.gtmLoginEvents.loginHyperlink)">
            {{ $t('login') }}
          </router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyPasswordStrength from '@/components/CyPasswordStrength.vue'
import CySSOButton from '@/components/CySsoButton.vue'
import oidc from '@/mixins/oidc'
import REGEX from '@/utils/config/regex'
import { gtmLoginEvents } from '@/utils/helpers/analytics'
import { required, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

const USERNAME_REGEX = /^[a-z]+[a-z0-9\-_]+[a-z0-9]+$/

export default {
  name: 'CyPageSignUp',
  components: {
    CyPasswordStrength,
    CySSOButton,
  },
  mixins: [oidc],
  beforeRouteEnter (_to, from, next) {
    if (from.name === 'accountVerification') {
      next({ name: 'login', params: from.params, query: from.query })
      return
    }
    next()
  },
  validations () {
    return {
      user: {
        givenName: {
          required,
          minLength: minLength(2),
          alphaWithSpecial (val) {
            if (!val) return true
            return REGEX.PROFILE_NAME.test(val)
          },
        },
        familyName: {
          required,
          minLength: minLength(2),
          alphaWithSpecial (val) {
            if (!val) return true
            return REGEX.PROFILE_NAME.test(val)
          },
        },
        username: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
          alphaNumeric (val) {
            if (!val) return true
            return USERNAME_REGEX.test(val)
          },
          endsWithCharacter (val) {
            return val.substr(-1) !== '_'
          },
        },
        email: {
          required,
          email: (val) => email(val.toLowerCase()),
        },
        ...(this.isOAuthSignup
          ? {}
          : {
              password: {
                required,
                minLength: minLength(8),
                minStrength () {
                  return this.passwordStrength > 0
                },
              },
              confirmPassword: {
                required,
                sameAsPassword: sameAs('password'),
              },
              locale: { required },
              countryCode: { required },
            }
        ),
        terms: {
          required,
          sameAs: sameAs(() => true),
        },
      },
    }
  },
  props: {
    isOauthSignupRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data: ({ $route, $i18n: { locale = 'en' } }) => ({
    passwordVisibility: false,
    confirmPasswordVisibility: false,
    isUserNameManuallyEdited: false,
    user: {
      givenName: '',
      familyName: '',
      username: '',
      email: $route.params.email || '',
      password: '',
      confirmPassword: '',
      countryCode: {
        en: 'GB',
        es: 'ES',
        fr: 'FR',
      }[locale],
      locale,
      terms: false,
    },
    loading: false,
    passwordStrength: 0,
  }),
  computed: {
    ...mapState({
      countries: (state) => state.countries,
    }),
    ...mapState('auth', {
      awsMarketplaceToken: (state) => state.awsMarketplaceToken,
      invitationToken: (state) => state.invitation.token,
      ssoErrors: (state) => state.errors.sso,
      signUpErrors: (state) => state.errors.signUp,
      socialProvider: (state) => state.socialProvider,
      socialProviders: (state) => state.socialProviders,
    }),
    ...mapGetters([
      'socialProviders',
    ]),
    $static: () => ({
      gtmLoginEvents,
    }),
    isOAuthSignup () {
      return (this.isOauthSignupRedirect || this.isOAuthSignupInProgress)
    },
    generatedUsername () {
      const escaper = (val) => val
        .replace(/[- ]/g, '_')
        .replace(/\W/g, '')

      return `${escaper(this.user.givenName)}_${escaper(this.user.familyName)}`.toLowerCase()
    },
    givenNameErrors () {
      const errors = []
      const { $dirty, required, minLength, alphaWithSpecial } = this.$v.user.givenName
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('fieldGivenNameLength'))
      if (!alphaWithSpecial) errors.push(this.$t('fieldGivenNameInvalid'))
      return errors
    },
    familyNameErrors () {
      const errors = []
      const { $dirty, required, minLength, alphaWithSpecial } = this.$v.user.familyName
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('fieldFamilyNameLength'))
      if (!alphaWithSpecial) errors.push(this.$t('fieldFamilyNameInvalid'))
      return errors
    },
    usernameErrors () {
      const errors = []
      const { $dirty, required, minLength, maxLength, alphaNumeric } = this.$v.user.username
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength || !maxLength) errors.push(this.$t('fieldUsernameLength'))
      if (!alphaNumeric) errors.push(this.$t('fieldUsernameInvalid'))
      return errors
    },
    emailErrors () {
      const errors = []
      const { $dirty, required, email } = this.$v.user.email
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!email) errors.push(this.$t('forms.fieldNotEmail'))
      return errors
    },
    passwordErrors () {
      const errors = []
      const { $dirty, required, minLength, minStrength } = this.$v.user.password
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('fieldPasswordMinLength'))
      if (!minStrength) errors.push(this.$t('fieldPasswordStrength'))
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      const { $dirty, required, sameAsPassword } = this.$v.user.confirmPassword
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!sameAsPassword) errors.push(this.$t('fieldConfirmPasswordSameAsPassword'))
      return errors
    },
    isEmailFieldReadonly () {
      return !_.isEmpty(this.$route.params.email)
    },
    createAccountText () {
      return {
        azuread: this.$t('btnCreateAccountAzure'),
        google: this.$t('btnCreateAccountGoogle'),
        github: this.$t('btnCreateAccountGitHub'),
      }[this.socialProvider]
    },
    errors () {
      return [
        ...this.signUpErrors,
        ...this.ssoErrors,
      ]
    },
    canSave () {
      return !this.$v.$invalid && !this.loading
    },
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane
    },
  },
  watch: {
    '$i18n.locale' () {
      this.user.locale = this.$i18n.locale
    },
    'user.givenName' () {
      if (!this.isUserNameManuallyEdited) this.user.username = this.generatedUsername
    },
    'user.familyName' () {
      if (!this.isUserNameManuallyEdited) this.user.username = this.generatedUsername
    },
    'user.username' (newVal) {
      if (_.isEmpty(newVal)) {
        this.isUserNameManuallyEdited = false
        this.user.username = this.generatedUsername
      }
    },
  },
  beforeMount () {
    if (this.isAuthenticated) this.$router.push({ name: 'login', query: this.$route.query })
  },
  async mounted () {
    this.getAwsMarketplaceToken()
    await this.FETCH_COUNTRIES()
  },
  methods: {
    ...mapActions([
      'FETCH_COUNTRIES',
    ]),
    ...mapActions('auth', [
      'SIGNUP',
    ]),
    onUserNameInput () {
      this.isUserNameManuallyEdited = true
    },
    async formSignup () {
      this.$v.$touch()
      this.loading = true

      const { invitationToken, user: { confirmPassword, terms, ...account } } = this
      await this.SIGNUP(_.$snakeCaseKeys({ ...account, invitationToken }))

      this.loading = false
      if (_.$isEmpty(this.errors)) {
        localStorage.setItem(LSK.SIGNUP_EMAIL, account.email)
        this.$router.push({ name: 'accountVerification', query: { ...this.$route.query, action: 'signupSuccess' }, params: { email: account.email } })
        this.$gtm.trackEvent(this.$static.gtmLoginEvents.createAccount)
      }
    },
    togglePasswordVisibility () {
      this.passwordVisibility = !this.passwordVisibility
    },
    toggleConfirmPasswordVisibility () {
      this.confirmPasswordVisibility = !this.confirmPasswordVisibility
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Sign up',
        alreadyHaveAccount: 'Already have an account?',
        btnCreateAccount: 'Create account',
        btnCreateAccountAzure: 'Create account with Azure AD',
        btnCreateAccountGitHub: 'Create account with GitHub',
        btnCreateAccountGoogle: 'Create account with Google',
        checkboxText: 'I agree to the {0} and {1}',
        fieldConfirmPassword: 'Confirm password',
        fieldConfirmPasswordSameAsPassword: 'It should be the same as the password',
        fieldFamilyName: 'Last name',
        fieldFamilyNameInvalid: 'Invalid format',
        fieldFamilyNameLength: 'The last name must have at least 2 characters',
        fieldGivenName: 'First name',
        fieldGivenNameInvalid: 'Invalid format',
        fieldGivenNameLength: 'The first name must have at least 2 characters',
        fieldPasswordMinLength: 'The password must have at least 8 characters',
        fieldPasswordStrength: 'The new password is too weak',
        fieldUsernameHint: 'A unique identifier used on the application',
        fieldUsernameInvalid: 'Invalid username',
        fieldUsernameLength: 'The username must have at least 3 and at most 30 characters',
        login: 'Log in',
        signUp: 'Sign up',
        signupWith: 'Or sign up with',
      },
      es: {
        title: 'Inscribirse',
        alreadyHaveAccount: '¿Ya tienes una cuenta?',
        btnCreateAccount: 'Crear cuenta',
        btnCreateAccountAzure: 'Crear cuenta con Azure AD',
        btnCreateAccountGitHub: 'Crear cuenta con GitHub',
        btnCreateAccountGoogle: 'Crear cuenta con Google',
        checkboxText: 'Acepto los {0} y la {1}',
        fieldConfirmPassword: 'Confirmar contraseña',
        fieldConfirmPasswordSameAsPassword: 'La confimación debe ser idéntica a la contraseña',
        fieldFamilyName: 'Apellido',
        fieldFamilyNameInvalid: 'Formato inválido',
        fieldFamilyNameLength: 'El apellido debe tener al menos 2 carácteres',
        fieldGivenName: 'Nombre',
        fieldGivenNameInvalid: 'Formato inválido',
        fieldGivenNameLength: 'El nombre debe tener al menos 2 carácteres',
        fieldPasswordMinLength: 'La contraseña debe tener al menos 8 caracteres',
        fieldPasswordStrength: 'La nueva contraseña es a la débil',
        fieldUsernameHint: 'Un identificante único usado en la aplicación',
        fieldUsernameInvalid: 'El nombre de usuario es inválido',
        fieldUsernameLength: 'El nombre de usuario debe tener entre 3 y 30 caracteres',
        login: 'Registrarse',
        signUp: 'Inscribirse',
        signupWith: 'Crear cuenta con',
      },
      fr: {
        title: `S'inscrire`,
        alreadyHaveAccount: 'Vous avez déjà un compte?',
        btnCreateAccount: 'Créer un compte',
        btnCreateAccountAzure: 'Créer un compte avec Azure AD',
        btnCreateAccountGitHub: 'Créer un compte avec GitHub',
        btnCreateAccountGoogle: 'Créer un compte avec Google',
        checkboxText: `J'accepte les {0} et la {1}`,
        fieldConfirmPassword: 'Confirmez le mot de passe',
        fieldConfirmPasswordSameAsPassword: 'La confirmation doit être identique au mot de passe',
        fieldFamilyName: 'Nom',
        fieldFamilyNameInvalid: 'Format invalide',
        fieldFamilyNameLength: 'Le nom doit contenir plus de 2 caractères',
        fieldGivenName: 'Prénom',
        fieldGivenNameInvalid: 'Format invalide',
        fieldGivenNameLength: 'Le prénom doit contenir plus de 2 caractères',
        fieldPasswordMinLength: 'Le mot de passe doit contenir au moins 8 caractères',
        fieldPasswordStrength: 'Le nouveau mot de passe est à la faible',
        fieldUsernameHint: `Un identifiant unique utilisé par l'application`,
        fieldUsernameInvalid: `Cet identifiant utilisateur n'est pas valide`,
        fieldUsernameLength: `Le nom d'utilisateur doit contenir entre 3 et 30 caractères`,
        login: 'Se connecter',
        signUp: 'Inscrivez-vous',
        signupWith: 'Créer un compte avec',
      },
    },
  },
}
</script>
